@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&family=Source+Code+Pro:wght@400;600&family=Source+Sans+Pro:wght@700&display=swap");

:root {
  --section-spacing: 10vw;
  --intro-width: 50vw;
  --contact-spacing: 25vw;
}

html {
  scroll-behavior: smooth;
}

button {
  background-color: #007bfe;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 58px;
  padding-right: 58px;
  color: #ffffff;
  border-radius: 8px;
  box-shadow: none;
  border-style: none;
  margin-bottom: 58px;
  cursor: pointer;
}

.intro-section-holder {
  background-color: #f8f9fa;
  width: 100vw;
  padding-top: 70px;
}

.intro-holder {
  padding-top: 57px;
  margin-left: var(--section-spacing);
  width: var(--intro-width);
}

.hey {
  font-size: 26px;
  padding-bottom: 24px;
  font-weight: 700;
  font-family: Source Sans Pro, sans-serif;
}

.title {
  font-size: 44px;
  padding-bottom: 20px;
  font-weight: 700;
  font-family: Source Sans Pro, sans-serif;
}

.introduction {
  font-size: 18px;
  padding-bottom: 30px;
  font-family: Nunito, sans-serif;
}

button {
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
}

.creds-section-holder {
  padding-top: 80px;
}

.creds-holder {
  display: flex;
  height: 224px;
  background-color: #f8f9fa;
  margin-left: var(--section-spacing);
  margin-right: var(--section-spacing);
  border-radius: 32px;
  align-items: center;
  justify-content: space-between;
}

.technologies {
  margin-right: var(--section-spacing);
}

.technology-title {
  text-align: center;
  font-size: 28px;
  font-family: Nunito, sans-serif;
  font-weight: 600;
}

.statement {
  margin-left: var(--section-spacing);
  font-family: Nunito, sans-serif;
}

.skills-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 65%;
  margin: auto;
}

.projects-section {
  padding-top: 30px;
}

.project-skills-holder {
  margin-bottom: 16px;
  margin-left: 0px;
  width: 65%;
}

.skill {
  color: #007bfe;
  background-color: #eef4ff;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 8px;
  font-family: Source Code Pro, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.project-holder {
  min-height: 420px;
  margin-left: var(--section-spacing);
  margin-right: var(--section-spacing);
  margin-top: 50px;
  border-style: solid;
  border-width: 2px;
  border-radius: 32px;
  border-color: #f4f5f5;
  display: flex;
}

.project-image {
  border-radius: 32px;
}

.project-content {
  padding-left: var(--section-spacing);
  padding-top: 55px;
  padding-right: 65px;
}

.project-title {
  font-size: 26px;
  font-family: Nunito, sans-serif;
  font-weight: 700;
}

.project-metadata {
  margin-top: 24px;
  margin-bottom: 12px;
  font-size: 18px;
  font-family: Source Code Pro, sans-serif;
}

.project-oneliner {
  font-weight: 700;
  margin-bottom: 12px;
  font-family: Nunito, sans-serif;
}

.project-description {
  margin-bottom: 20px;
  font-family: Nunito, sans-serif;
}

.project-btn-holder {
  display: flex;
  flex-direction: row;
}

.contact-page-holder {
  border-style: solid;
  border-width: 2px;
  border-color: #f4f5f5;
  border-radius: 32px;
  height: 625px;
  margin-left: var(--section-spacing);
  margin-right: var(--section-spacing);
  margin-top: 80px;
}

.contact-content {
  padding-top: 57px;
  padding-left: var(--contact-spacing);
  padding-right: var(--contact-spacing);
}

.contact-title {
  font-size: 38px;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-weight: 600;
}

.text-input-holder {
  font-family: Source Code Pro, sans-serif;
  margin-top: 18px;
}

.input-title {
  margin-bottom: 10px;
}

input,
textarea {
  border: none;
  background-color: #f8f9fa;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  font-family: Source Code Pro, sans-serif;
  padding-left: 8px;
}

input {
  height: 40px;
}

textarea {
  height: 148px;
  padding-top: 9px;
}

input:focus,
textarea:focus {
  border-color: #007bfe;
  border-width: 2px;
  border-style: solid;
  outline: none;
}

.contact-btn-holder {
  padding-top: 40px;
  text-align: center;
}

.navbar-holder {
  height: 70px;
  box-shadow: 0px 6px 6px 0px #00000029;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background-color: #ffffff;
  top: 0;
  z-index: 1000;
}

.nav-content-holder {
  display: flex;
  padding-top: 24px;
  position: absolute;
  right: 8px;
}

.nav-section {
  padding-right: 48px;
  color: #000000;
  text-decoration: none;
}

.highlighted {
  color: #007bfe;
}

.error-text {
  font-size: 12px;
  color: red;
}

@media (max-width: 1170px) {
  :root {
    --section-spacing: 5vw;
    --intro-width: 60vw;
    --contact-spacing: 20vw;
  }
}

@media (max-width: 880px) {
  :root {
    --section-spacing: 2vw;
    --intro-width: 70vw;
    --contact-spacing: 15vw;
  }

  .project-holder {
    display: block;
  }

  .project-image {
    transform: translateX(50%);
    margin-top: 16px;
  }

  .project-content {
    padding-top: 10px;
  }
}

@media (max-width: 500px) {
  :root {
    --intro-width: 80vw;
    --contact-spacing: 10vw;
  }

  .statement {
    display: none;
  }

  .nav-section {
    padding-right: 32px;
    font-size: 14px;
  }

  .project-skills-holder {
    width: 80%;
  }

  .project-btn-holder {
    flex-wrap: wrap;
  }

  .project-btn-holder > a > button {
    margin-bottom: 12px;
  }
  

}

@media (max-width: 375px) {
  .hey {
    font-size: 20px;
  }

  .title {
    font-size: 28px;
  }

  .introduction {
    font-size: 12px;
  }

  .technology-title {
    font-size: 20px;
  }

  .statement {
    font-size: 12px;
  }

  .skill {
    font-size: 9px;
  }

  .project-title {
    font-size: 18px;
  }

  .project-metadata {
    font-size: 12px;
  }

  .project-oneliner {
    font-size: 9px;
  }

  .project-description {
    font-size: 9px;
  }

  .contact-title {
    font-size: 28px;
  }

  .input-title {
    font-size: 12px;
  }

  .nav-section {
    padding-right: 16px;
  }

  .project-skills-holder {
    width: 100%;
  }

  button {
    padding-left: 32px;
    padding-right: 32px;
  }


}
